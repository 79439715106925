import { FC, useState, useEffect, useMemo, useCallback } from 'react'
import { Checkbox, Avatar, Badge } from '@aptive-env/storybook'

import Filters from 'components/Filters'
import TableHeader from 'components/TableHeader'
import TableFooter from 'components/TableFooter'
import styles from './index.module.scss'
import {
  SalesRepresentative,
  License,
  State,
  Season,
  Team,
  Attachment
} from 'interface'
import Direction from 'components/Direction'
import LicenseModal from './License'
import ViewDetailDrawer from './ViewDetail'
import {
  fetchSalesRepresentatives,
  exportSalesRepresentatives,
  fetchStates,
  fetchLicenses,
  fetchSeasons,
  fetchTeams,
  fetchLicenseAttachments
} from 'services'

const sortableTHeadClassName = 'relative flex items-center gap-2 cursor-pointer text-xs uppercase tracking-[0.03em] p-3 text-gray-600 hover:text-gray-950 [&_svg]:hover:stroke-[#030712]'

const SalesRepresentativesView: FC = () => {
  const [states, setStates] = useState<State[]>([])
  const [seasons, setSeasons] = useState<Season[]>([])
  const [teams, setTeams] = useState<Team[]>([])
  const [isAllChecked, setIsAllChecked] = useState(false)
  const [salesRepresentatives, setSalesRepresentatives] = useState<SalesRepresentative[]>([])
  const [filter, setFilter] = useState<Record<string, string>>({})
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState({ name: '', direction: 1 })
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalCount, setTotalCount] = useState(0)
  const [isLicenseModalOpen, setIsLicenseModalOpen] = useState(false)
  const [selectedSalesRepresentative, setSelectedSalesRepresentative] = useState<SalesRepresentative | null>(null)
  const [isSalesRepresentativeViewOpen, setIsSalesRepresentativeViewOpen] = useState(false)
  const [selectedLicense, setSelectedLicense] = useState<License | null>(null)
  const [salesRepresentativeLicenses, setSalesRepresentativeLicenses] = useState<License[]>([])
  const [selectedLicenseAttachments, setSelectedLicenseAttachments] = useState<Attachment[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const filterOptions = useMemo(() => {
    return {
      season: { name: 'Season', items: seasons.map((season) => season.name) },
      team: { name: 'Team', items: teams.map((team) => team.name) },
      license: { name: 'License', items: ['Licensed', 'Unlicensed'] },
      filters: { name: 'Filters' },
      download: { name: 'Download' }
    }
  }, [seasons, teams])

  const selectedSeason = useMemo(() => seasons.find((season) => season.name === filter.season), [filter.season, seasons])

  const selectedTeam = useMemo(() => teams.find((team) => team.name === filter.team), [filter.team, teams])

  const filterLicense = useMemo(() => filter.license === 'Licensed' ? 1 : filter.license === 'Unlicensed' ? 0 : undefined, [filter.license])

  const loadData = useCallback(async () => {
    if (selectedTeam) {
      setIsLoading(true)
      const salesRepresentativesResponse = await fetchSalesRepresentatives({
        season_id: selectedSeason?.id ?? undefined,
        team_id: selectedTeam.id,
        license: filterLicense,
        search: search || undefined,
        sortBy: sort.name || undefined,
        orderBy: sort.name ? (sort.direction === 1 ? 'asc' : 'desc') : undefined,
        page: currentPage,
        per_page: pageSize,
      })
      const tempSalesRepresentatives = salesRepresentativesResponse.result.reps.map((salesRepresentative) => {
        salesRepresentative.selected = false
        return salesRepresentative
      })
  
      setSalesRepresentatives(tempSalesRepresentatives)
      setTotalCount(salesRepresentativesResponse._metadata.pagination.total)
      setIsLoading(false)
    } else {
      setSalesRepresentatives([])
      setTotalCount(0)
    }
  }, [selectedSeason, selectedTeam, filterLicense, search, sort, currentPage, pageSize])

  const fetchInitialValues = async () => {
    const [statesResponse, seasonsResponse, teamsResponse] = await Promise.all([
      fetchStates(),
      fetchSeasons(),
      fetchTeams()
    ])
    
    setStates(statesResponse.result.states)
    setSeasons(seasonsResponse.result.seasons)
    setTeams(teamsResponse.result.teams)
    if (teamsResponse.result.teams[0]) {
      setFilter({
        team: teamsResponse.result.teams[0].name
      })
    }
  }

  useEffect(() => {
    fetchInitialValues()
  }, [])

  useEffect(() => {
    // Call API Endpoint with Search, Filter, and Pagination
    loadData()
  }, [loadData])

  const toggleAll = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault()
    setIsAllChecked(!isAllChecked)
    const updatedSalesRepresentatives = [...salesRepresentatives]
    updatedSalesRepresentatives.forEach((salesRepresentative) => {
      salesRepresentative.selected = !isAllChecked
    })
    setSalesRepresentatives(updatedSalesRepresentatives)
  }

  const toggleOne = (event: React.MouseEvent<HTMLElement, MouseEvent>, id: number) => {
    event.preventDefault()
    event.stopPropagation()

    let isAllChecked = true
    const updatedSalesRepresentatives = [...salesRepresentatives]
    updatedSalesRepresentatives.forEach((salesRepresentative) => {
      if (salesRepresentative.id === id) {
        salesRepresentative.selected = !salesRepresentative.selected
      }
      isAllChecked = isAllChecked && salesRepresentative.selected as boolean
    })
    setIsAllChecked(isAllChecked)
    setSalesRepresentatives(updatedSalesRepresentatives)
  }

  const onHandleSort = (name: string) => {
    setSort({ name, direction: sort.name === name ? -sort.direction : 1 })
  }

  const handleClickView = (salesRepresentative: SalesRepresentative) => {
    setSelectedSalesRepresentative(salesRepresentative)
    setIsSalesRepresentativeViewOpen(true)
  }

  const handleDownload = async () => {
    const exportResponse = await exportSalesRepresentatives()
    const blob = new Blob([exportResponse], { type: 'text/csv' })

    const a = document.createElement('a')
    a.download = 'sales-representatives.csv'
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  const onLicenseAdd = () => {
    setSelectedLicense(null)
    setIsLicenseModalOpen(true)
  }

  const onLicenseEdit = async (license: License) => {
    const licensesResponse = await fetchLicenses(license.license_id)
    const uploadedAttachment = await fetchLicenseAttachments(license.license_id)
    
    setSelectedLicense(licensesResponse.result.license)
    setSelectedLicenseAttachments(uploadedAttachment.result.attachments)
    setIsLicenseModalOpen(true)
  }

  return (
    <div className="flex flex-col h-0 grow overflow-hidden p-3">
      <div className="flex justify-between items-center h-[70px] px-3">
        <h1 className="text-[32px] leading-10">Sales Representatives</h1>
        <Filters
          values={filter}
          options={filterOptions}
          onChange={(value) => setFilter({ ...filter, ...value })}
          onDownload={handleDownload}
        />
      </div>
      <div className="flex flex-col flex-1 px-3 pb-3 overflow-hidden">
        <TableHeader
          search={search}
          setSearch={setSearch}
          addLabel="Add License"
          addNewModalOpen={onLicenseAdd}
        />
        <div className="flex flex-col overflow-hidden border-l border-r border-gray-200">
          <div className="flex flex-none gap-[34px] bg-gray-50 border-b border-gray-200">
            <div className="flex justify-center items-center w-[48px]">
              <Checkbox
                checked={isAllChecked}
                onClick={toggleAll}
                className={styles.checkBox}
              />
            </div>
            <div className="w-[74px] text-center text-xs text-gray-600 uppercase tracking-[0.03em] p-3">Photo</div>
            <div
              className={`grow ${sortableTHeadClassName} ${sort.name === 'name' ? 'text-gray-950' : ''}`}
              onClick={() => onHandleSort('name')}
            >
              <span>Name</span>
              <Direction sort={sort.name === 'name' ? sort.direction : 0} />
            </div>
            <div
              className={`justify-end w-[90px] ${sortableTHeadClassName} ${sort.name === 'id' ? 'text-gray-950' : ''}`}
              onClick={() => onHandleSort('id')}
            >
              <span>Id</span>
              <Direction sort={sort.name === 'id' ? sort.direction : 0} />
            </div>
            <div
              className={`grow ${sortableTHeadClassName} ${sort.name === 'team' ? 'text-gray-950' : ''}`}
              onClick={() => onHandleSort('team')}
            >
              <span>Team</span>
              <Direction sort={sort.name === 'team' ? sort.direction : 0} />
            </div>
            <div
              className={`justify-center w-[180px] uppercase ${sortableTHeadClassName} ${sort.name === 'license' ? 'text-gray-950' : ''}`}
              onClick={() => onHandleSort('license')}
            >
              <span>License</span>
              <Direction sort={sort.name === 'license' ? sort.direction : 0} />
            </div>
          </div>
          <div className="flex flex-col flex-auto overflow-y-auto no-scrollbar">
            {isLoading && (
              <div className="flex w-full h-40 items-center justify-center">
                <span className="text-lg">Loading data...</span>
              </div>
            )}
            {!isLoading && salesRepresentatives.length === 0 && (
              <div className="flex w-full h-40 items-center justify-center">
                <span className="text-lg">No data</span>
              </div>
            )}
            {!isLoading && salesRepresentatives.length > 0 && salesRepresentatives.map((salesRepresentative) => (
              <div
                key={salesRepresentative.id}
                className="flex gap-[34px] border-b border-gray-200 bg-white cursor-pointer last:border-none"
                onClick={() => handleClickView(salesRepresentative)}
              >
                <div className="flex justify-center items-center w-[48px]">
                  <Checkbox
                    checked={salesRepresentative.selected}
                    onClick={(event) => toggleOne(event, salesRepresentative.id)}
                    className={styles.checkBox}
                  />
                </div>
                <div className="flex justify-center items-center w-[74px] text-center p-3">
                  <Avatar size="medium" imgSrc={salesRepresentative.avatar_url} />
                </div>
                <div className="flex items-center flex-1 text-sm text-gray-600 p-3">{salesRepresentative.name}</div>
                <div className="flex items-center justify-end text-sm text-gray-600 w-[87px] p-3">{salesRepresentative.id}</div>
                <div className="flex items-center flex-1 text-sm text-gray-600 p-3">
                  {salesRepresentative.team?.name}
                </div>
                <div className="flex items-center justify-center w-[180px] text-center text-sm text-gray-600 p-3">
                  <Badge
                    size="small"
                    color={`${salesRepresentative.license_available && salesRepresentative.license_available > 0 ? 'success' : 'error'}`}
                    className="!text-xs !font-normal"
                  >
                    {salesRepresentative.license_available}/{salesRepresentative.license_assigned}
                  </Badge>
                </div>
              </div>
            ))}
          </div>
        </div>
        <TableFooter
          page={currentPage}
          setPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalResults={totalCount}
        />
      </div>
      
      {isLicenseModalOpen && (
        <LicenseModal
          isOpen={isLicenseModalOpen}
          onClose={() => setIsLicenseModalOpen(false)}
          license={selectedLicense}
          salesRepresentative={selectedSalesRepresentative}
          setSalesRepresentativeLicenses={setSalesRepresentativeLicenses}
          selectedLicenseAttachments={selectedLicenseAttachments}
          states={states}
        />
      )}
      {isSalesRepresentativeViewOpen && (
        <ViewDetailDrawer
          isOpen={isSalesRepresentativeViewOpen}
          onClose={() => {
            setIsSalesRepresentativeViewOpen(false)
            setSelectedSalesRepresentative(null)
          }}
          salesRepresentative={selectedSalesRepresentative}
          salesRepresentativeLicenses={salesRepresentativeLicenses}
          setSalesRepresentativeLicenses={setSalesRepresentativeLicenses}
          states={states}
          onLicenseAdd={onLicenseAdd}
          onLicenseEdit={onLicenseEdit}
        />
      )}
    </div>
  )
}

export default SalesRepresentativesView
