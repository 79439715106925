import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ConfigCatProvider } from 'configcat-react'

const container = document.getElementById('root')
const root = createRoot(container!)

const key = process.env.REACT_APP_CONFIGCAT_SDK_KEY ?? ''

root.render(
  <StrictMode>
    <ConfigCatProvider sdkKey={key}>
      <App />
    </ConfigCatProvider>
  </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
